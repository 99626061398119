import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, Typography, Box, useTheme, useMediaQuery } from '@material-ui/core';

import { useStores } from '../../hooks/useStores';
import { ISubjectItem } from '../../types/SubjectTypes';
import ProfileLearnProgressNumber from './ProfileLearnProgressNumber';
import SubjectCard from '../Subject/SubjectCard';
import Loader from '../UI/Loader';

const ProfileContent: React.FC = observer(() => {
    const { ProfileStore: store } = useStores();
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        store.getMySubjects();
    }, [store]);

    return (
        <>
            <Box mb={4}>
                <Grid container spacing={isSM ? 2 : 3}>
                    <Grid item xs={12} sm={6}>
                        <ProfileLearnProgressNumber value={store.totalLessonsCompleted} loading={store.loading}>
                            Узган дәресләрнең саны
                        </ProfileLearnProgressNumber>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ProfileLearnProgressNumber value={store.totalLessonsLeft} loading={store.loading}>
                            Калган дәресләрнең саны
                        </ProfileLearnProgressNumber>
                    </Grid>
                </Grid>
            </Box>

            <Box>
                <Box mb={{ xxs: 2, sm: 3, lg: 4 }}>
                    <Typography variant="h5">Минем фәннәр</Typography>
                </Box>

                <Box display="flex" flexDirection="column" alignItems="center">
                    {store.loading && <Loader minHeight={150} />}
                    {!store.loading && store.mySubjects?.length
                        ? store.mySubjects.map((subject: ISubjectItem) => (
                              <SubjectCard key={subject.id} data={subject} showProgress dense />
                          ))
                        : null}
                </Box>
            </Box>
        </>
    );
});

export default ProfileContent;
