import React from 'react';
import { Box, Typography, Paper, Avatar, makeStyles, List, ListItem } from '@material-ui/core';

import Slider from 'react-slick';
import '../scss/slick.scss';

import CarouselArrow from './UI/CarouselArrow';
import { ISubjectTeacher } from '../types/SubjectTypes';

interface ITeachersCarouselProps {
    teachers: ISubjectTeacher[];
}

const TeachersCarousel: React.FC<ITeachersCarouselProps> = ({ teachers }) => {
    const classes = useStyles();
    const settings = {
        dots: true,
        infinite: false,
        speed: 700,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: <CarouselArrow type="prev" />,
        nextArrow: <CarouselArrow type="next" />,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <>
            <Box mb={3}>
                <Typography variant="h5">Безнең мөгаллимнәр</Typography>
            </Box>

            <Box mx={-2}>
                <Slider {...settings}>
                    {teachers.map((teacher: ISubjectTeacher, index: number) => (
                        <Box
                            key={index}
                            width="100%"
                            height="100%"
                            display="flex !important"
                            justifyContent="center"
                            px={{ xxs: 2, sm: 1, xl: 2 }}
                        >
                            <Box width="100%" maxWidth={{ xxs: 320, md: 'none' }}>
                                <Paper elevation={0} variant="outlined" className={classes.paper}>
                                    <Box pt={8} pb={2} px={2}>
                                        <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
                                            <Avatar src={teacher.avatar} className={classes.avatar} />
                                        </Box>

                                        <Typography variant="h6" align="center">
                                            {teacher.name}
                                        </Typography>

                                        {teacher.description.length > 0 && (
                                            <List>
                                                {teacher.description.map(
                                                    (item, index) =>
                                                        item && (
                                                            <ListItem key={index} disableGutters className={classes.descriptionItem}>
                                                                {item}
                                                            </ListItem>
                                                        ),
                                                )}
                                            </List>
                                        )}
                                    </Box>
                                </Paper>
                            </Box>
                        </Box>
                    ))}
                </Slider>
            </Box>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    paper: {
        height: '100%',
    },
    avatar: {
        width: 184,
        height: 184,
        [theme.breakpoints.down('sm')]: {
            width: 150,
            height: 150,
        },
    },
    descriptionItem: {
        textAlign: 'center',
        justifyContent: 'center',
        color: theme.palette.surface.main,
    },
}));

export default TeachersCarousel;
