import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, useTheme, useMediaQuery } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { ISubjectProgram, ISubjectLesson } from '../../types/SubjectTypes';
import { useStyles } from './style';

interface ISubjectProgramItemProps {
    program: ISubjectProgram;
    index: number;
    showDoneText?: boolean;
    dense?: boolean;
}

const SubjectProgramItem: React.FC<ISubjectProgramItemProps> = ({ program, index, showDoneText = false, dense = false }) => {
    const classes = useStyles();
    const { subjectId } = useParams();
    const [expanded, setExpanded] = useState(program.status === 'progress');

    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));
    const isMD = useMediaQuery(theme.breakpoints.down('md'));

    // Effects
    useEffect(() => {
        if (program.status === 'progress') {
            setExpanded(true);
        }
        return () => {
            setExpanded(false);
        };
    }, [program.status]);

    // Handlers
    const handleExpand = (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded);
    };

    const getLessonClasses = (status: ISubjectLesson['status']) => {
        switch (status) {
            case 'done':
                return classes.doneLink;

            case 'progress':
                return classes.currentLink;

            default:
                return classes.disableLink;
        }
    };

    // Render
    return (
        <Accordion
            key={index}
            className={dense && isMD ? classes.accordionMobile : classes.accordion}
            expanded={expanded}
            onChange={handleExpand}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.accordionSummary}
                IconButtonProps={dense ? { className: classes.expandIconDense } : {}}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" pr={3}>
                    <Typography className={classes.headTitle}>
                        {index + 1}. {program.title}
                    </Typography>
                    {showDoneText &&
                        program.status === 'done' &&
                        (isXS ? (
                            <CheckCircleIcon className={classes.doneMarkIcon} />
                        ) : (
                            <div className={classes.doneMarkText}>Башкарылды!</div>
                        ))}
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box display="flex" flexDirection="column" mt="-12px">
                    {program.lessons.map((lesson: ISubjectLesson, subIndex: number) => (
                        <Box display="flex" alignItems="center" key={subIndex} pl={{ xxs: 0, sm: 3 }} mb={1}>
                            <Box mr={1} height="24px" className={lesson.status === 'done' ? classes.visible : classes.hidden}>
                                <CheckIcon color="secondary" />
                            </Box>
                            {lesson.status === 'notAvailable' ? (
                                <Typography variant="body2" className={getLessonClasses(lesson.status)}>
                                    {index + 1}.{subIndex + 1}. {lesson.title}
                                </Typography>
                            ) : (
                                <Link to={`/subjects/${subjectId}/lesson/${lesson.id}`} className={getLessonClasses(lesson.status)}>
                                    {index + 1}.{subIndex + 1}. {lesson.title}
                                </Link>
                            )}
                        </Box>
                    ))}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default SubjectProgramItem;
