import React from 'react';
import { Box, Typography, Grid, useTheme, useMediaQuery, Theme, makeStyles } from '@material-ui/core';

import img1 from '../../img/about/training-steps/img1.png';
import img2 from '../../img/about/training-steps/img2.png';
import img3 from '../../img/about/training-steps/img3.png';

const TrainingStepsSection: React.FC = () => {
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Box mb={{ xxs: 8, md: 12 }}>
                <Typography align="center" variant={isSM ? 'h4' : 'h3'}>
                    Уку-укыту тәртибе:
                </Typography>
            </Box>

            <StepItem img={img1} title="Теркәлү">
                Татар мәдрәсәсе порталында шәхси аккаунт булдырыгыз. Нәтиҗәләрегез турында дусларыгыз һәм язылучыларыгызга сөйләп бару өчен
                социаль челтәрләрдәге аккаунтларыгызны беркетегез.
            </StepItem>

            <StepItem img={img2} title="Онлайн дәресләр" reverse>
                Сез "Татар мәдрәсәсе" сайтында, шулай ук IOS һәм Android платформалы телефоннар өчен эшләнгән мобиль кушымталар аша 7 Ислам
                фәненнән видео- һәм аудиодәресләр карый/тыңлый, текстлар укый аласыз.
            </StepItem>

            <StepItem img={img3} title="Тест узу">
                Алган белемнәрне ныгыту һәм киләсе темага күчү өчен, һәр темадан соң тәкъдим ителгән тестны узыгыз.
            </StepItem>
        </>
    );
};

interface StepItemSectionProps {
    img: string;
    title: string;
    children: any;
    reverse?: boolean;
}

const StepItem: React.FC<StepItemSectionProps> = ({ title, img, children, reverse }) => {
    const classes = useStyles();

    return (
        <Box mb={{ xxs: 5, md: 7, xl: 11 }}>
            <Grid container spacing={4}>
                <Grid container item xs={12} md={6} direction="column" justify="center" className={reverse ? classes.order2 : ''}>
                    <Box className={reverse ? classes.reverseContentBox : classes.contentBox}>
                        <Box mb={{ xxs: 2, md: 3 }}>
                            <Typography variant="h5">{title}</Typography>
                        </Box>
                        <Typography color="textSecondary">{children}</Typography>
                    </Box>
                </Grid>
                <Grid container item xs={12} md={6} justify="center" className={reverse ? classes.order1 : ''}>
                    <img src={img} alt="" style={{ width: '100%' }} />
                </Grid>
            </Grid>
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    order1: {
        [theme.breakpoints.up('md')]: {
            order: 1,
        },
    },
    order2: {
        [theme.breakpoints.up('md')]: {
            order: 2,
        },
    },
    contentBox: {
        paddingRight: theme.spacing(9),
        [theme.breakpoints.down('lg')]: {
            paddingRight: theme.spacing(6),
        },
        [theme.breakpoints.down('md')]: {
            paddingRight: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(0),
        },
    },
    reverseContentBox: {
        paddingLeft: theme.spacing(9),
        [theme.breakpoints.down('lg')]: {
            paddingLeft: theme.spacing(6),
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(0),
        },
    },
}));

export default TrainingStepsSection;
