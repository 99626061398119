import React from 'react';
import { observer } from 'mobx-react-lite';
import { Container, Box, Typography } from '@material-ui/core';

import logo from '../../img/logo.png';
import { useStyles } from '../../components/Auth/style';
import { useStores } from '../../hooks/useStores';
import LoaderButton from '../../components/UI/LoaderButton';
import TextButton from '../../components/UI/TextButton';
import { useHistory } from 'react-router-dom';

const NotConfirm: React.FC = observer(() => {
    const classes = useStyles();
    const history = useHistory();
    const { AuthStore: store } = useStores();

    const handleResend = () => {
        store.resendConfirmLink();
    };

    const handleLogout = () => {
        store.logout(history);
    };

    return (
        <Container maxWidth="xl">
            <Box className={classes.authRow}>
                <Box mb={15}>
                    <Box className={classes.card}>
                        <img src={logo} alt="" className={classes.logo} />

                        <Box mb={3}>
                            <Typography variant="h6">Безнең сайтта теркәлгәнегез өчен рәхмәт!</Typography>
                        </Box>
                        <Box mb={3} textAlign="center">
                            <Typography color="textSecondary">
                                Аккаунтны раслау өчен, почтагызга җибәрелгән сылтама буенча узыгыз.
                            </Typography>
                        </Box>

                        <Box mb={3} width="100%">
                            <LoaderButton fullWidth color="primary" variant="contained" onClick={handleResend} loading={store.loading}>
                                Сылтаманы кабаттан җибәрергә.
                            </LoaderButton>
                        </Box>
                        <Box mb={-1}>
                            <TextButton color="default" onClick={handleLogout}>
                                Чыгу
                            </TextButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
});

export default NotConfirm;
