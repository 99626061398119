import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Container, makeStyles, Theme, Box, Typography, Button } from '@material-ui/core';
import TextButton from '../components/UI/TextButton';

import img1 from '../img/main/mock_img1.jpg';
import img2 from '../img/main/mock_img2.jpg';
import img3 from '../img/main/mock_img3.jpg';
import img5 from '../img/main/mock_img5.jpg';
import img6 from '../img/main/mock_img6.jpg';
import img7 from '../img/main/mock_img7.jpg';
import img8 from '../img/main/mock_img8.jpg';

import legalInfoIcon from '../img/legal-info-icon.svg';

const Main: React.FC = () => {
    const classes = useStyles();
    const bgImgs = [img1, img2, img3, img5, img6, img7, img8];
    const settings = {
        infinite: true,
        speed: 1000,
        fade: true,
        dots: false,
        arrows: false,
        autoplay: true,
        pauseOnHover: false,
        autoplaySpeed: 4000,
        slidesToScroll: 1,
        slidesToShow: 1,
    };

    return (
        <Box className={classes.main}>
            <Container maxWidth="xl" className={classes.container}>
                <Box display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                    <Box mb={4}>
                        <Typography variant="h1" className={classes.title}>
                            Интернет-мәдрәсә
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb="8vh">
                        <Box mr={{ xxs: 3, md: 5 }}>
                            <Link to="/login" className="router-link">
                                <Button variant="contained" color="primary" className={classes.btn} size="large">
                                    Керү
                                </Button>
                            </Link>
                        </Box>
                        <Link to="/register" className="router-link">
                            <TextButton className={classes.textBtn}>Теркәлү</TextButton>
                        </Link>
                    </Box>

                    <div className={classes.legalInfo}>
                        <img src={legalInfoIcon} alt="" />
                        <p>
                            Проект по обучению религии, реализуемый Духовным управлением мусульман <br /> Республики Татарстан согласно
                            Федеральному закону #125-ФЗ
                        </p>
                    </div>
                </Box>
            </Container>
            <Box className={classes.bgSliderWrap}>
                <Slider {...settings} className={classes.bgSlider}>
                    {bgImgs.map((img: string, index: number) => (
                        <img src={img} alt="" key={index} />
                    ))}
                </Slider>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        position: 'relative',
        width: '100%',
        height: '100vh',
        backgroundColor: '#000',
        zIndex: 1,
    },
    container: {
        height: '100%',
        paddingBottom: '6vh',
        [theme.breakpoints.down('md')]: {
            paddingBottom: '4vh',
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: '3vh',
        },
    },
    bgSliderWrap: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
        '&:before': {
            content: `''`,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(90deg, rgba(0, 40, 143, 0.32) 0%, rgba(228, 235, 253, 0) 50%, rgba(0, 40, 143, 0.32) 100%)',
            zIndex: 1,
        },
    },
    bgSlider: {
        height: '100%',
        '& img': {
            width: '100%',
            height: '100vh',
            objectFit: 'cover',
        },
    },
    title: {
        fontSize: 72,
        lineHeight: '72px',
        fontWeight: 600,
        color: '#fff',
        [theme.breakpoints.down('lg')]: {
            fontSize: 64,
            lineHeight: '64px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 54,
            lineHeight: '54px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
            lineHeight: '40px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 32,
            lineHeight: '32px',
        },
    },
    btn: {
        minWidth: 160,
        height: 48,
        borderRadius: 19,
    },
    textBtn: {
        color: '#fff',
        textDecoration: 'underline',
    },
    legalInfo: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 10,
        lineHeight: '14px',
        color: '#fff',
        opacity: 0.6,
        '& img': {
            width: 21,
            height: 21,
            marginRight: 10,
        },
        [theme.breakpoints.down('xs')]: {
            '& br': {
                display: 'none',
            },
        },
    },
}));

export default Main;
