import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Container, Box, Typography } from '@material-ui/core';
import { ISubjectItem } from '../types/SubjectTypes';
import SubjectCard from '../components/Subject/SubjectCard';
import { useStores } from '../hooks/useStores';
import Loader from '../components/UI/Loader';

const Subjects: React.FC = observer(() => {
    const { SubjectStore: store, AuthStore, ProfileStore } = useStores();

    useEffect(() => {
        store.getSubjects();
    }, [store]);

    return (
        <Container maxWidth="xl">
            <Box my={4}>
                <Typography variant="h5">Уку-укыту программасы</Typography>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" mb={10}>
                {store.loading && <Loader minHeight={300} />}
                {!store.loading &&
                    store.subjects?.map((subject: ISubjectItem) => (
                        <SubjectCard
                            key={subject.id}
                            data={subject}
                            progressPlace="topImg"
                            showProgress={AuthStore.isAuth && ProfileStore.confirmed}
                        />
                    ))}
            </Box>
        </Container>
    );
});

export default Subjects;
