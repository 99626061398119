import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Box, Grid, Typography, Paper, makeStyles, Theme } from '@material-ui/core';
import { useStores } from '../../hooks/useStores';
import { ISubjectItem } from '../../types/SubjectTypes';
import Loader from '../UI/Loader';
import { observer } from 'mobx-react-lite';

const CourseProgramSection: React.FC = observer(() => {
    const { SubjectStore: store } = useStores();

    useEffect(() => {
        store.getSubjects();
    }, [store]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <Box pr={{ xxs: 0, md: 5, lg: 7, xl: 11 }}>
                    <Box mb={3}>
                        <Typography variant="h4">Курс программасы</Typography>
                    </Box>
                    <Typography
                        color="textSecondary"
                        component="div"
                        dangerouslySetInnerHTML={{
                            __html: `
                            <p>Укыту процессы Татарстан Республикасы Диния нәзарәте тарафыннан расланган «Дини тәрбия бирү» программасына нигезләнеп алып барыла.</p>
                            <p>Программада Ислам дине нигезләре, татарларда Ислам тарихы, данлыклы татар галимнәре турында мәгълүмат, интеллектуаль Ислам һәм мәгърифәтчелек темалары каралган.</p>
                            <p>Әлеге курс төрле яшьтәге кулланучыларга туры килә, ә читтән торып уку формасы иң кулай уку сәгатьләрен сайлау мөмкинлеге бирә.</p>
                            <p>Бөтен уку-укыту материаллары Татарстан Республикасы Диния нәзарәтенең Экспертлар советы тарафыннан тикшерелде: материаллар Хәнәфи мәзһәбе һәм Матуриди гакыйдәсенә туры килә.</p>
                            `,
                        }}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
                {store.loading && <Loader minHeight={150} />}
                {store.subjects.map((subject: ISubjectItem) => (
                    <CourseItem key={subject.id} to={`/subjects/${subject.id}`}>
                        {subject.title}
                    </CourseItem>
                ))}
            </Grid>
        </Grid>
    );
});

interface CourseItemProps {
    to: string;
    children: string;
}

const CourseItem: React.FC<CourseItemProps> = ({ to, children }) => {
    const classes = useStyles();

    return (
        <Box mb={{ xxs: 2, xl: 3 }}>
            <Link to={to} className="router-link">
                <Paper elevation={0} variant="outlined" className={classes.paper}>
                    <Box p={{ xxs: 2, sm: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                </Paper>
            </Link>
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        transition: 'all .3s ease',
        '&:hover': {
            transform: 'translateY(-2px)',
            borderColor: theme.palette.primary.main,
            boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.05), 0px 1px 22px rgba(0, 0, 0, 0.04), 0px 3px 9px rgba(0, 0, 0, 0.08)',
        },
    },
}));

export default CourseProgramSection;
