import React from 'react';
import SocialBtn from './SocialBtn';

interface ISocialBtnsListProps {
    type: 'signin' | 'signup';
}

const SocialBtnsList: React.FC<ISocialBtnsListProps> = ({ type }) => {
    if (type === 'signup') {
        return (
            <>
                <SocialBtn provider="google">Google аша теркәлергә</SocialBtn>
                <SocialBtn provider="apple">Apple аша теркәлергә</SocialBtn>
                <SocialBtn provider="facebook">Facebook аша теркәлергә</SocialBtn>
                <SocialBtn provider="vkontakte">Вконтакте аша теркәлергә</SocialBtn>
                <SocialBtn provider="odnoklassniki">Одноклассники аша теркәлергә</SocialBtn>
                <SocialBtn provider="email">Электрон почта аша теркәлергә</SocialBtn>
            </>
        );
    }
    return (
        <>
            <SocialBtn provider="google">Google аша керергә</SocialBtn>
            <SocialBtn provider="apple">Apple аша керергә</SocialBtn>
            <SocialBtn provider="facebook">Facebook аша керергә</SocialBtn>
            <SocialBtn provider="vkontakte">Вконтакте аша керергә</SocialBtn>
            <SocialBtn provider="odnoklassniki">Одноклассники аша керергә</SocialBtn>
            <SocialBtn provider="email">Электрон почта аша керергә</SocialBtn>
        </>
    );
};

export default SocialBtnsList;
