import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Container, makeStyles, Theme, Typography } from '@material-ui/core';

import error404 from '../img/error_404.png';

const NotFound: React.FC = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="xl">
            <Box className={classes.root}>
                <Box className={classes.row} textAlign="center">
                    <img src={error404} alt="" className={classes.errorCode} />

                    <Box mb={4} textAlign="center">
                        <Typography color="textSecondary">
                            Сез бетерелгән яки башка адреска күчерелгән аккаунт адресын керттегез. Шуңа күрә әлеге язуны күрәсез.
                        </Typography>
                    </Box>

                    <Link to="/" className="router-link">
                        <Button fullWidth color="primary" variant="contained" className={classes.btn}>
                            Төп биткә күчү
                        </Button>
                    </Link>
                </Box>
            </Box>
        </Container>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
    },
    row: {
        width: 543,
        marginBottom: theme.spacing(12),
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    errorCode: {
        maxWidth: 521,
        [theme.breakpoints.down('lg')]: {
            maxWidth: 470,
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: 410,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    btn: {
        width: 236,
        [theme.breakpoints.down('xs')]: {
            width: 180,
        },
    },
}));
export default NotFound;
