import { ISubjectProgram } from '../../types/SubjectTypes';
import { getNextAvailableProgramId } from './getNextAvailableProgramId';
import { getProgramStatus } from './getProgramStatus';
import { getControlledStatusLessons } from './getControlledStatusLessons';

export const getProgramsWithStatus = (programs: ISubjectProgram[]) => {
    const nextAvailableProgramId = getNextAvailableProgramId(programs);
    const programsWithStatus = programs.map((program: ISubjectProgram) => {
        const { completed, lessons, id } = program;
        return {
            ...program,
            status: getProgramStatus(completed, lessons, nextAvailableProgramId === id),
            lessons: getControlledStatusLessons(lessons, nextAvailableProgramId === id),
        };
    });

    return programsWithStatus;
};
