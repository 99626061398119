import React, { useState, useEffect } from 'react';
import YandexAudio from 'ya-music/src/index.js';
import clsx from 'clsx';
import moment from 'moment';

import { makeStyles, Theme, Typography, Grid, Box, CircularProgress, Slider, withStyles, useTheme, useMediaQuery } from '@material-ui/core';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import PauseCircleOutlineOutlinedIcon from '@material-ui/icons/PauseCircleOutlineOutlined';

const transformTimes = (t: number) => {
    return moment().startOf('day').seconds(t).format('mm:ss');
};

interface IAudioPlayerProps {
    src: string;
    onPlay: any;
    completed: boolean;
    onComplete: () => void;
}

const AudioPlayer: React.FC<IAudioPlayerProps> = ({ src, onPlay, completed, onComplete }) => {
    const classes = useStyles();
    const [player, setPlayer] = useState<any>(null);
    const [isReady, setIsReady] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const [startedPlay, setStartedPlay] = useState(false);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [volume, setVolume] = useState(1);

    const [progress, setProgress] = useState<number>(0);
    const [slidingStart, setSlidingStart] = useState(false);
    const [slidingPos, setSlidingPos] = useState(0);

    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));
    const isXSM = useMediaQuery('(max-width: 425px)');

    // Init Player
    useEffect(() => {
        const player = new YandexAudio('html5', null);

        player.initPromise().then(
            () => {
                setPlayer(player);
                setIsReady(true);
                // setVolume(player.getVolume());
            },
            (err: string) => {
                setIsReady(false);
                setIsError(true);
                console.log('Error', err);
            },
        );

        return () => {
            setStartedPlay(false);
            player.stop();
            player.clearListeners();
        };
    }, []);

    // Player events
    if (player && isReady) {
        player.on(YandexAudio.EVENT_PROGRESS, (timings: any) => {
            const progressPersent = parseInt(((timings.position / timings.duration) * 100).toFixed(2));

            setProgress(timings.position);
            setCurrentTime(timings.position);

            if (!completed && progressPersent >= 75) {
                onComplete();
            }
        });

        player.on(YandexAudio.EVENT_STATE, function (state: any) {
            if (state === YandexAudio.STATE_PLAYING) {
                setIsPlaying(true);
            } else {
                setIsPlaying(false);
            }
        });
    }

    //// Handlers
    const startPlay = () => {
        if (player && isReady) {
            if (player.isPreloaded(src)) {
                player.playPreloaded(src);
            } else {
                player.play(src);
            }

            setStartedPlay(true);
            onPlay();
        }
    };

    const play = () => {
        if (player && isReady) {
            const state = player.getState();

            switch (state) {
                case YandexAudio.STATE_PLAYING:
                    player.pause();
                    break;
                case YandexAudio.STATE_PAUSED:
                    player.resume();
                    break;
                default:
                    startPlay();
                    break;
            }
        }
    };

    const handleSetSlidingPosition = (e: any, value: any) => {
        if (!slidingStart) {
            setSlidingStart(true);
        }
        setSlidingPos(value);
    };

    const handleSetPosition = (e: any, value: any) => {
        player.setPosition(value);
        setTimeout(() => {
            setSlidingStart(false);
        }, 1000);
    };

    const handleSetVolume = (e: any, value: any) => {
        if (player && isReady) {
            setVolume(value);
            player.setVolume(value);
        }
    };

    //// Renders
    const renderBtn = () => {
        if (player && isReady) {
            if (isPlaying) {
                return (
                    <button className={classes.playBtn} onClick={play}>
                        <PauseCircleOutlineOutlinedIcon className={classes.playBtnIcon} />
                    </button>
                );
            }

            return (
                <button className={classes.playBtn} onClick={play} disabled={!Boolean(src)}>
                    <PlayCircleFilledWhiteOutlinedIcon className={clsx(classes.playBtnIcon, !Boolean(src) ? classes.disabled : '')} />
                </button>
            );
        }

        return (
            <button className={classes.playBtn}>
                <CircularProgress size={29} />
            </button>
        );
    };

    return (
        <div className={classes.player}>
            {isError ? (
                <Typography>Плеерны йөкләүдә хата бар</Typography>
            ) : (
                <Grid container spacing={isXSM ? 1 : 2} alignItems="center">
                    <Grid item className={classes.controls}>
                        {renderBtn()}
                    </Grid>
                    <Grid item xs>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant={isXSM ? 'body2' : 'body1'}>Дәресләрнең аудиоязмасы</Typography>
                            <Typography variant={isXSM ? 'body2' : 'body1'}>{transformTimes(currentTime)}</Typography>
                        </Box>

                        {Boolean(src) && (
                            <Box mt={1}>
                                <AudioSlider
                                    value={slidingStart ? slidingPos : progress}
                                    max={player?.getDuration()}
                                    onChange={handleSetSlidingPosition}
                                    onChangeCommitted={handleSetPosition}
                                    disabled={!startedPlay}
                                />
                            </Box>
                        )}
                    </Grid>
                    {Boolean(src) && !isSM && (
                        <Grid>
                            <Box minWidth={112} ml={4} mr={2}>
                                <Box mb={1}>
                                    <Typography className={classes.volumeLabel}>Тавыш</Typography>
                                </Box>
                                <AudioSlider value={volume} step={0.1} max={1} onChange={handleSetVolume} />
                            </Box>
                        </Grid>
                    )}
                </Grid>
            )}
        </div>
    );
};

const AudioSlider = withStyles({
    root: { height: 8, padding: 0 },
    track: { height: 4 },
    rail: { height: 4 },
    thumb: {
        marginTop: -4,
        marginLeft: -3,
        '&.Mui-disabled': {
            width: 12,
            height: 12,
            marginTop: -4,
            marginLeft: -3,
        },
    },
})(Slider);

const useStyles = makeStyles((theme: Theme) => ({
    player: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '72px',
        padding: theme.spacing(2),
        borderRadius: '8px',
        backgroundColor: '#FFF0EB',
    },
    controls: {
        width: 51,
    },
    playBtn: {
        display: 'flex',
        cursor: 'pointer',
        color: theme.palette.primary.main,
        border: 'none',
        outline: 'none',
        padding: 0,
        background: 'none',
        transition: 'all .3s ease',
        '&:hover': {
            color: theme.palette.primary.dark,
        },
    },
    playBtnIcon: {
        fontSize: 34,
    },
    disabled: {
        cursor: 'default',
        color: theme.palette.surface.light,
        '&:hover': {
            color: theme.palette.surface.light,
        },
    },
    volumeLabel: {
        color: theme.palette.surface.main,
    },
}));

export default AudioPlayer;
