import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import icon1 from '../../img/about/icon2_1.svg';
import icon2 from '../../img/about/icon2_2.svg';
import icon3 from '../../img/about/icon2_3.svg';
import icon4 from '../../img/about/icon2_4.svg';
import icon5 from '../../img/about/icon2_5.svg';
import icon6 from '../../img/about/icon2_6.svg';
import icon7 from '../../img/about/icon2_7.svg';

const CourseAdvantagesSection: React.FC = () => {
    return (
        <>
            <Box mb={5}>
                <Typography variant="h4">Безнең курсларда Сез:</Typography>
            </Box>
            <Grid container spacing={4}>
                <AdvantagItem icon={icon1}>Гарәп телендә укырга һәм язарга өйрәнәчәксез;</AdvantagItem>
                <AdvantagItem icon={icon2}>Гыйбадәт төрләренең (намаз, ураза, хаҗ, зәкәт) барлык шартларына төшенәчәксез;</AdvantagItem>
                <AdvantagItem icon={icon3}>Коръәнне гарәп телендә укый башлаячаксыз;</AdvantagItem>
                <AdvantagItem icon={icon4}>Ислам дине гыйлеме турында тирән белемгә ия булачаксыз;</AdvantagItem>
                <AdvantagItem icon={icon5}>Пәйгамбәребез Мөхәммәднең (с.г.в.) тормыш юлы белән танышачаксыз;</AdvantagItem>
                <AdvantagItem icon={icon6}>Ислам этикасын һәм әхлак нормаларын үзләштерәчәксез;</AdvantagItem>
                <AdvantagItem icon={icon7}>Ислам гыйлемен көндәлек тормышта кулланырга өйрәнәчәксез;</AdvantagItem>
            </Grid>
        </>
    );
};

interface AdvantagItemProps {
    icon: string;
    children: string;
}

const AdvantagItem: React.FC<AdvantagItemProps> = ({ icon, children }) => {
    return (
        <Grid item xs={12} sm={6} md={4} xl={3}>
            <Box mb={{ xxs: 2, md: 3 }}>
                <Box mb={{ xxs: 2, md: 3 }}>
                    <img src={icon} alt="" />
                </Box>
                <Box maxWidth={{ xxs: 360, sm: 'auto' }}>
                    <Typography>{children}</Typography>
                </Box>
            </Box>
        </Grid>
    );
};

export default CourseAdvantagesSection;
