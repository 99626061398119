import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import logo from '../../img/logo.png';
import SocialBtnsList from '../../components/Auth/SocialBtnsList';
import TextButton from '../../components/UI/TextButton';

import { useStyles } from '../../components/Auth/style';
import { useStores } from '../../hooks/useStores';
import Loader from '../../components/UI/Loader';
import SocialAuthService from '../../services/SocialAuthService';

const SignIn: React.FC = observer(() => {
    const classes = useStyles();
    const { AuthStore: store } = useStores();
    const history = useHistory();
    const [requestSended, setRequestSended] = useState(false);

    // Effects
    useEffect(() => {
        SocialAuthService.setCode(history.location.search);
    }, [history.location.search]);

    useEffect(() => {
        SocialAuthService.storageEventListener(code => {
            if (!requestSended) {
                store.socialLogin(code, history);
                setRequestSended(true);
            }
        });

        return () => {
            SocialAuthService.clearListeners();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Renders
    return (
        <Container maxWidth="xl">
            {store.loadingSocial && <Loader isAbsolute={Number(true)} />}
            <Box className={classes.authRow}>
                <Box className={classes.card} p={{ xxs: 2, sm: 4 }}>
                    <img src={logo} alt="" className={classes.logo} />

                    <Box mb={3} textAlign="center">
                        <Typography variant="h6">Керү</Typography>
                    </Box>

                    <Box width="100%" display="flex" flexDirection="column" mb={1}>
                        <SocialBtnsList type="signin" />
                    </Box>

                    <Link to="/register" className={classes.link}>
                        <TextButton>Теркәлү</TextButton>
                    </Link>
                </Box>
            </Box>
        </Container>
    );
});

export default SignIn;
