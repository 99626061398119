import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import { Paper, Box, Typography, TextField, Button, Avatar, makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';

import TextButton from '../UI/TextButton';
import FormLabelCustom from '../UI/FormLabelCustom';
import { useStores } from '../../hooks/useStores';
import Loader from '../UI/Loader';

const ProfileUserInfo: React.FC = observer(() => {
    const classes = useStyles();
    const { ProfileStore: store, AuthStore } = useStores();
    const [userName, setUserName] = useState<string>('');
    const [avatar, setAvatar] = useState<Blob | string>('');

    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));

    const loading = store.updateLoading.userInfo;

    //// Effects
    useEffect(() => {
        return () => {
            store.setIsEdit('userInfo', false);
        };
    }, [store]);

    useEffect(() => {
        if (store.profile) {
            const { name = '', photo } = store.profile || {};

            photo && setAvatar(photo);
            name && setUserName(name);
        }
    }, [store.profile]);

    //// Handlers
    const getPhoto = () => {
        if (avatar) {
            if (typeof avatar === 'string') {
                return avatar;
            }

            return URL.createObjectURL(avatar);
        }

        return avatar;
    };

    const handleChange = (event: any) => {
        setUserName(event.target.value);
    };

    const handleFileChange = (event: any) => {
        event.preventDefault();

        setAvatar(event.target.files[0]);
    };

    const handleSave = (event: any) => {
        if (store.profile?.id) {
            const form = new FormData(); // used formData because photo need send as blob file

            form.append('name', userName);

            if (typeof avatar !== 'string') {
                form.append('photo', avatar);
            }

            store.updateProfile(store.profile?.id, form, 'userInfo');
        }
    };

    const handleCancel = () => {
        const { name, photo } = store.profile || {};

        photo && setAvatar(photo);
        name && setUserName(name);

        store.setIsEdit('userInfo', false);
    };

    const handleSuccessRemove = () => {
        AuthStore.resetStore();
        // history.push('/');
    };

    const handleRemove = () => {
        if (window.confirm('Сез дөрестән дә профильне бетерергә телисезме?')) {
            if (store.profile?.id) {
                store.removeProfile(store.profile?.id, 'userInfo', handleSuccessRemove);
            }
        }
    };

    //Renders
    const renderForm = () => {
        return (
            <>
                <Box mb={4}>
                    <label className={classes.fileLabel}>
                        <input type="file" name="userAvatar" onChange={handleFileChange} hidden />
                        <Avatar className={clsx(classes.avatar, classes.avatarEdit)}>
                            <img src={getPhoto()} alt="" />
                            <PhotoCameraOutlinedIcon />
                        </Avatar>
                    </label>
                </Box>
                <Box mb={3} width="100%">
                    <FormLabelCustom required>Исем</FormLabelCustom>
                    <TextField
                        variant="outlined"
                        size="small"
                        name="name"
                        value={userName}
                        onChange={handleChange}
                        placeholder="Исемегезне языгыз"
                        fullWidth
                        error={!!store.errorMessages?.name}
                        helperText={store.errorMessages?.name}
                        disabled={loading}
                    />
                </Box>
                <Box mb={2} width="100%">
                    <Button fullWidth color="primary" variant="contained" onClick={handleSave}>
                        Сакларга
                    </Button>
                </Box>
                <Box mb={2}>
                    <TextButton onClick={handleCancel}>Баш тартырга</TextButton>
                </Box>
                <Box>
                    <TextButton onClick={handleRemove} color="default">
                        Профильне бетерергә
                    </TextButton>
                </Box>
            </>
        );
    };

    const renderView = () => {
        return (
            <>
                <Box mb={{ xxs: 2, lg: 4 }}>
                    <Avatar src={getPhoto()} className={classes.avatar} />
                </Box>
                <Box mb={{ xxs: 2, sm: 3 }} maxWidth="100%" textAlign="center">
                    <Typography className={classes.userName} variant={isXS ? 'h6' : 'h5'}>
                        {userName || store.profile?.email}
                    </Typography>
                </Box>
                <TextButton onClick={() => store.setIsEdit('userInfo', true)}>Төзәтмәләр кертү</TextButton>
            </>
        );
    };

    return (
        <Paper elevation={0} variant="outlined">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                pt={{ xxs: 3, lg: 5, xl: 7 }}
                p={{ xxs: 2, lg: 3, xl: 4 }}
                minHeight={362}
            >
                {loading && <Loader />}
                {!loading && store.profile ? (store.isEdit.userInfo ? renderForm() : renderView()) : null}
            </Box>
        </Paper>
    );
});

const useStyles = makeStyles(theme => ({
    avatar: {
        position: 'relative',
        width: 128,
        height: 128,
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    },
    userName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '100%',
    },
    avatarEdit: {
        '&:before': {
            content: `''`,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0, 0, 0, 0.54)',
        },
        '& .MuiSvgIcon-root': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            fontSize: 48,
            opacity: 0.5,
            transform: 'translate(-50%, -50%)',
            transition: 'opacity .3s ease',
        },
    },
    fileLabel: {
        cursor: 'pointer',
        '&:hover': {
            '& .MuiSvgIcon-root': {
                opacity: 1,
            },
        },
    },
}));

export default ProfileUserInfo;
