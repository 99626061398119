import { action, observable, runInAction } from 'mobx';
import { api } from '../api';
import { ITestData } from '../types/LessonTestTypes';

interface ITestValues {
    [key: string]: any;
}

export class LessonTestStore {
    @observable loading = false;
    @observable sendLoading = false;

    @observable values: ITestValues = {};

    @observable testData: ITestData | null = null;
    @observable result: any = null;
    // @observable incorrectAnswers: ITestResultItem[] = mockIncorrectAnswers;

    @action
    getTest = (testId: number) => {
        this.loading = true;

        api.get(`/tests/${testId}`)
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        this.testData = response.data.data;
                    });
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    };

    @action
    sendTest = (userId: number, testId: number, history: any) => {
        this.sendLoading = true;
        const answers: ITestValues = {};

        // transform answers
        for (const [key, value] of Object.entries(this.values)) {
            if (Array.isArray(value)) {
                const answersArr: [] = [];

                value.forEach((value: string) => {
                    // @ts-ignore
                    answersArr.push(parseInt(value));
                });

                answers[`${key}`] = answersArr;
            } else {
                // @ts-ignore
                answers[`${key}`] = [parseInt(value)];
            }
        }

        const data = {
            user_id: userId,
            // @ts-ignore
            test_id: parseInt(testId),
            answers,
        };

        api.post('tests/check', data)
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        this.result = response.data.data;
                        history.push(`${history.location.pathname}/result`);
                    });
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.sendLoading = false;
                });
            });
    };

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        runInAction(() => {
            if (e.target.type === 'radio') {
                this.values = {
                    ...this.values,
                    [e.target.name]: e.target.value,
                };
            } else {
                if (e.target.checked) {
                    // add checked item
                    this.values = {
                        ...this.values,
                        [e.target.name]: [...(this.values[e.target.name] || []), e.target.value],
                    };
                } else {
                    // remove uncheckeded item value
                    this.values = {
                        ...this.values,
                        [e.target.name]: this.values[e.target.name].filter((value: string) => value !== e.target.value),
                    };
                }
            }
        });
    };

    @action
    resetStore = () => {
        this.loading = false;
        this.testData = null;
        this.values = {};
    };

    @action
    clearResultData = () => {
        this.result = null;
    };
}
