import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import {
    AppBar,
    Grid,
    makeStyles,
    Container,
    Box,
    Avatar,
    Typography,
    IconButton,
    Drawer,
    useMediaQuery,
    useTheme,
    ListItem,
    List,
    Divider,
    Theme,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { useStores } from '../../hooks/useStores';

import MenuLink from './MenuLink';
import logo from '../../img/logo.png';
import logoWhiteSolid from '../../img/logo_white_solid.png';

interface IHeaderProps {
    isMainPage: boolean;
}

const Header: React.FC<IHeaderProps> = observer(({ isMainPage }) => {
    const classes = useStyles();
    const { AuthStore, ProfileStore } = useStores();
    const [mobilMenuOpen, setMobilMenuOpen] = useState<boolean>(false);
    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down('md'));

    const handleDrawerOpen = () => {
        setMobilMenuOpen(true);
    };

    const handleDrawerClose = () => {
        setMobilMenuOpen(false);
    };

    return (
        <AppBar position="static" className={clsx(classes.header, isMainPage ? classes.isMainPage : '')} elevation={0} color="transparent">
            <Container maxWidth="xl">
                <Box display="flex" alignItems="center" py={{ xxs: 1, sm: 1.5, lg: 2.5 }} justifyContent="space-between">
                    <Box mr={3}>
                        <Link to="/">
                            <img src={isMainPage ? logoWhiteSolid : logo} alt="Logo" className={classes.logo} />
                        </Link>
                    </Box>

                    {!isMD && (
                        <Grid container spacing={4} wrap="nowrap" justify="flex-end" className={classes.menu}>
                            <Grid item>
                                <MenuLink to="/subjects" theme={isMainPage ? 'dark' : 'white'}>
                                    Уку-укыту программасы
                                </MenuLink>
                            </Grid>
                            {/* <Grid item>
                                <MenuLink to="/news" theme={isMainPage ? 'dark' : 'white'}>
                                    Яңалыклар
                                </MenuLink>
                            </Grid> */}
                            <Grid item>
                                <MenuLink to="/about" theme={isMainPage ? 'dark' : 'white'}>
                                    Проект турында
                                </MenuLink>
                            </Grid>
                            {!AuthStore.isAuth ? (
                                <Grid item>
                                    <MenuLink to="/login" theme={isMainPage ? 'dark' : 'white'}>
                                        Керергә
                                    </MenuLink>
                                </Grid>
                            ) : (
                                <Grid item>
                                    <MenuLink to="/profile" theme={isMainPage ? 'dark' : 'white'}>
                                        Профиль
                                    </MenuLink>
                                </Grid>
                            )}
                        </Grid>
                    )}
                    {isMD && (
                        <>
                            <Box color={isMainPage ? '#fff' : '#000'}>
                                <IconButton edge="start" color="inherit" size="small" onClick={handleDrawerOpen}>
                                    <MenuIcon />
                                </IconButton>
                            </Box>
                            <Drawer anchor="right" open={mobilMenuOpen} onClose={handleDrawerClose}>
                                <Box minWidth={320} pt={1} pb={3}>
                                    <Box display="flex" justifyContent="flex-end" px={2}>
                                        <IconButton edge="end" color="inherit" onClick={handleDrawerClose}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>

                                    {AuthStore.isAuth && (
                                        <>
                                            <Box display="flex" alignItems="center" px={2}>
                                                <Box mr={2}>
                                                    <Avatar src={ProfileStore.profile?.photo || ''} />
                                                </Box>
                                                <Typography>{ProfileStore.profile?.name}</Typography>
                                            </Box>

                                            <Box mt={3} mb={1} mx={2}>
                                                <Divider />
                                            </Box>
                                        </>
                                    )}

                                    <List>
                                        <ListItem>
                                            <MenuLink to="/subjects" onClick={handleDrawerClose}>
                                                Уку-укыту программасы
                                            </MenuLink>
                                        </ListItem>
                                        {/* <ListItem>
                                            <MenuLink to="/news" onClick={handleDrawerClose}>
                                                Яңалыклар
                                            </MenuLink>
                                        </ListItem> */}
                                        <ListItem>
                                            <MenuLink to="/about" onClick={handleDrawerClose}>
                                                Проект турында
                                            </MenuLink>
                                        </ListItem>
                                        {!AuthStore.isAuth ? (
                                            <ListItem>
                                                <MenuLink to="/login" onClick={handleDrawerClose}>
                                                    Керергә
                                                </MenuLink>
                                            </ListItem>
                                        ) : (
                                            <ListItem>
                                                <MenuLink to="/profile" theme={isMainPage ? 'dark' : 'white'} onClick={handleDrawerClose}>
                                                    Профиль
                                                </MenuLink>
                                            </ListItem>
                                        )}
                                    </List>
                                </Box>
                            </Drawer>
                        </>
                    )}
                </Box>
            </Container>
        </AppBar>
    );
});

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        zIndex: 2,
    },
    menu: {
        width: '100%',
    },
    logo: {
        width: 71,
        [theme.breakpoints.down('lg')]: {
            width: 60,
        },
        [theme.breakpoints.down('md')]: {
            width: 40,
        },
        [theme.breakpoints.down('xs')]: {
            width: 32,
        },
    },
    link: {
        textDecoration: 'none',
    },
    signInBtn: {
        display: 'block',
        width: 68,
        [theme.breakpoints.down('md')]: {
            width: 116,
        },
    },
    menuAvatar: {
        width: 72,
        height: 72,
        marginBottom: theme.spacing(2),
    },
    isMainPage: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
    },
}));

export default Header;
