import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Box, Typography, TextField } from '@material-ui/core';

import { useStores } from '../../hooks/useStores';
import { ISignInEmail } from '../../types/AuthTypes';
import TextButton from '../../components/UI/TextButton';
import FormLabelCustom from '../../components/UI/FormLabelCustom';
import PasswordInput from '../../components/UI/PasswordInput';

import logo from '../../img/logo.png';
import { useStyles } from '../../components/Auth/style';
import LoaderButton from '../../components/UI/LoaderButton';

const SignIn: React.FC = observer(() => {
    const { AuthStore: store } = useStores();
    const classes = useStyles();
    const history = useHistory();
    const [values, setValues] = useState<ISignInEmail>({
        email: '',
        password: '',
    });

    //// Effects
    useEffect(() => {
        return () => {
            store.clearErrors();
        };
    }, [store]);

    useEffect(() => {
        const state: any = history.location.state;
        const email = state?.email;

        if (email) {
            setValues(prevValues => ({
                ...prevValues,
                email,
            }));
        }
    }, [history.location]);

    //// Handlers
    const handleChange = (event: any) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleLogin = () => {
        store.login(values, history);
    };

    //// Renders
    return (
        <Container maxWidth="xl">
            <Box className={classes.authRow}>
                <Box className={classes.card}>
                    <img src={logo} alt="" className={classes.logo} />

                    <Box mb={3} textAlign="center">
                        <Typography variant="h6">Электрон почта аша керергә</Typography>
                    </Box>

                    <Box width="100%" display="flex" flexDirection="column">
                        <Box mb={{ xxs: 2, sm: 3 }}>
                            <FormLabelCustom required>Email</FormLabelCustom>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                name="email"
                                placeholder="Email адресын языгыз"
                                value={values.email}
                                onChange={handleChange}
                                error={!!store.errorMessages?.email}
                                helperText={store.errorMessages?.email}
                                disabled={store.loading}
                            />
                        </Box>
                        <Box mb={2}>
                            <FormLabelCustom required>Серсүз</FormLabelCustom>
                            <PasswordInput
                                name="password"
                                placeholder="Серсүзне языгыз"
                                value={values.password}
                                onChange={handleChange}
                                error={!!store.errorMessages?.password}
                                helperText={store.errorMessages?.password}
                                disabled={store.loading}
                            />
                        </Box>
                        <Box mb={{ xxs: 3, sm: 4 }} textAlign="right">
                            <Link
                                to={{
                                    pathname: '/reset-password',
                                    state: { email: values.email },
                                }}
                                className={classes.link}
                            >
                                <TextButton>Серсүзне оныттыгызмы?</TextButton>
                            </Link>
                        </Box>
                    </Box>

                    <LoaderButton fullWidth color="primary" variant="contained" onClick={handleLogin} loading={store.loading}>
                        Керү
                    </LoaderButton>
                </Box>
            </Box>
        </Container>
    );
});

export default SignIn;
