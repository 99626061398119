import React from 'react';
import { observer } from 'mobx-react-lite';
import { Container, Box, Typography, Button } from '@material-ui/core';

import logo from '../../img/logo.png';
import { useStyles } from '../../components/Auth/style';
import { Link } from 'react-router-dom';

const NotConfirm: React.FC = observer(() => {
    const classes = useStyles();

    return (
        <Container maxWidth="xl">
            <Box className={classes.authRow}>
                <Box mb={15}>
                    <Box className={classes.card}>
                        <img src={logo} alt="" className={classes.logo} />

                        <Box mb={3}>
                            <Typography variant="h6">Котлыйбыз!</Typography>
                        </Box>
                        <Box mb={3} textAlign="center">
                            <Typography color="textSecondary">
                                Сезнең аккаунтыгыз расланды. <br /> Хәзер Сез курсның тулы программасын карый аласыз.
                            </Typography>
                        </Box>

                        <Link to="/" className="router-link" style={{ width: '100%' }}>
                            <Button fullWidth color="primary" variant="contained">
                                Фәннәр исемлегенә
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
});

export default NotConfirm;
