import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Container, Typography, Box, Grid, Button, useTheme, useMediaQuery } from '@material-ui/core';
import { InlineIcon } from '@iconify/react';
import exitOutline from '@iconify/icons-ion/exit-outline';

import ProfileUserInfo from '../components/Profile/ProfileUserInfo';
import ProfileEmailForm from '../components/Profile/ProfileEmailForm';
import ProfilePasswordForm from '../components/Profile/ProfilePasswordForm';
// import ProfileNotificationToggler from '../components/Profile/ProfileNotificationToggler';
import { useStores } from '../hooks/useStores';
import ProfileContent from '../components/Profile/ProfileContent';
import LoaderButton from '../components/UI/LoaderButton';

const Profile: React.FC = observer(() => {
    const { AuthStore, ProfileStore } = useStores();
    const history = useHistory();

    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down('md'));

    // Handlers
    const handleLogout = () => {
        AuthStore.logout(history);
    };

    const handleDownload = () => {
        ProfileStore.getCertificate();
    };

    // Renders
    const renderMobile = () => {
        return (
            <>
                <Box mb={{ xxs: 3 }}>
                    <ProfileUserInfo />
                </Box>

                <Box mb={{ xxs: 3 }}>
                    <LoaderButton
                        loading={ProfileStore.loadingCertificate}
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleDownload}
                    >
                        Котлау хатын алырга
                    </LoaderButton>
                </Box>

                <Box mb={4}>
                    <ProfileContent />
                </Box>

                <Box mb={{ xxs: 2, md: 3 }}>
                    <ProfileEmailForm />
                </Box>
                <Box mb={{ xxs: 2, md: 3 }}>
                    <ProfilePasswordForm />
                </Box>
                {/* <Box mb={{ xxs: 2, md: 3 }}>
                    <ProfileNotificationToggler />
                </Box> */}
                <Box mb={4}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        endIcon={<InlineIcon icon={exitOutline} />}
                        onClick={handleLogout}
                    >
                        Чыгу
                    </Button>
                </Box>
            </>
        );
    };

    const renderPC = () => {
        return (
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Box mb={{ lg: 3, xl: 4 }}>
                        <ProfileUserInfo />
                    </Box>

                    <Box mb={{ lg: 3, xl: 4 }}>
                        <LoaderButton
                            loading={ProfileStore.loadingCertificate}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleDownload}
                        >
                            Котлау хатын алырга
                        </LoaderButton>
                    </Box>

                    <Box mb={{ lg: 3, xl: 4 }}>
                        <ProfileEmailForm />
                    </Box>
                    <Box mb={{ lg: 3, xl: 4 }}>
                        <ProfilePasswordForm />
                    </Box>
                    {/* <Box mb={{ lg: 3, xl: 4 }}>
                        <ProfileNotificationToggler />
                    </Box> */}

                    <Button fullWidth variant="outlined" color="primary" endIcon={<InlineIcon icon={exitOutline} />} onClick={handleLogout}>
                        Чыгу
                    </Button>
                </Grid>

                <Grid item xs={8}>
                    <ProfileContent />
                </Grid>
            </Grid>
        );
    };

    return (
        <Container maxWidth="xl">
            <Box my={3}>
                <Typography variant="h4">Профиль</Typography>
            </Box>

            <Box mb={{ xxs: 7, md: 10 }}>{isMD ? renderMobile() : renderPC()}</Box>
        </Container>
    );
});

export default Profile;
