import React, { Fragment, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Container, Box, Typography, Button, Divider } from '@material-ui/core';

import { useStores } from '../hooks/useStores';
import Loader from '../components/UI/Loader';
import { ITestQuestion } from '../types/LessonTestTypes';
import TestQuestion from '../components/LessonTest/TestQuestion';

const LessonTest: React.FC = observer(() => {
    const { LessonTestStore: store, ProfileStore } = useStores();
    const history = useHistory();
    const { subjectId, testId } = useParams();

    // Effects
    useEffect(() => {
        store.getTest(testId);
        return () => {
            store.resetStore();
        };
    }, [store, subjectId, testId]);

    // Handlers
    const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (ProfileStore.profile) {
            store.sendTest(ProfileStore.profile?.id, testId, history);
        }
    };

    // Renders
    const renderContent = () => {
        if (store.testData) {
            const { caption, queries } = store.testData;

            return (
                <>
                    <Box mb={{ xxs: 4, md: 6 }}>
                        <Typography variant="h5">{caption}</Typography>
                    </Box>

                    <form onSubmit={handleSubmit}>
                        <Box mb={8}>
                            {queries.map((item: ITestQuestion, index: number) => (
                                <Fragment key={item.id}>
                                    <TestQuestion question={item} index={index + 1} />
                                    {queries.length !== index + 1 && (
                                        <Box mt={2} mb={3} ml={8}>
                                            <Divider />
                                        </Box>
                                    )}
                                </Fragment>
                            ))}
                        </Box>

                        <Box display="flex" justifyContent="flex-end" mt={-4} mb={{ xxs: 5, sm: 9 }}>
                            <Box width={{ xxs: '100%', sm: '328px' }}>
                                <Button fullWidth variant="contained" color="primary" type="submit">
                                    Тәмамларга
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </>
            );
        }

        return null;
    };

    return (
        <Container maxWidth="lg">
            <Box mt={4} mb={3}>
                <Typography variant="h4">Тест</Typography>
            </Box>

            {store.loading && <Loader minHeight={200} />}
            {!store.loading && store.testData && renderContent()}
        </Container>
    );
});

export default LessonTest;
