import React from 'react';
import { Container, Box, makeStyles, Theme, useTheme, useMediaQuery, Typography } from '@material-ui/core';

const PrivacyPolicy = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Container maxWidth="xl">
            <Box my={{ xxs: 4, lg: 6 }}>
                <Box mt={4} mb={6}>
                    <Typography variant={isXS ? 'h5' : 'h4'}>Конфиденциальлек сәясәте</Typography>
                </Box>
                <Box className={classes.content}>
                    <p>
                        <strong>&laquo;Хозур&raquo; Нәшрият йортында шәхси мәгълүматны саклау принциплары.</strong>
                    </p>
                    <p>
                        &laquo;Хозур&raquo; Нәшрият Йорты шәхси мәгълүматны саклауга бик җаваплы карый. Түбәндә тәкъдим ителгән биш принцип
                        конфиденциальлек сәясәтенең нигезе булып тора:
                    </p>
                    <ol>
                        <li>
                            Үзегез турындагы мәгълүматны биреп, безгә күрсәткән ышанычыгызны югары бәялибез. Сезнең шәхси мәгълүматны без
                            һәрвакыт намуслы һәм лаеклы юл белән файдаланачакбыз.
                        </li>
                        <li>
                            Сезнең шәхси мәгълүматны ничек куллануыбыз турында тулы һәм дөрес мәгълүмат алырга хакыгыз бар. Безнең нинди
                            мәгълүмат җыюыбыз, аның белән нәрсә эшләвебез, кем белән уртаклашуыбыз, әгәр сездә нинди дә булса авырлыклар
                            туса, кемгә мөрәҗәгать итәргә кирәклеге Сезгә һәрвакыт аңлаешлы булырга тиеш.
                        </li>
                        <li>
                            Әгәр сезнең шәхси мәгълүматны ничек куллануыбыз турында сорауларыгыз булса, сорауларга җавап бирергә шат
                            булачакбыз.
                        </li>
                        <li>
                            Шәхси мәгълүматны хокуксыз файдаланудан яклау өчен, барлык кирәкле чараларны күрәбез һәм аны куркынычсыз урында
                            саклыйбыз.
                        </li>
                        <li>
                            Без шәхси мәгълүматларны яклау турындагы гамәлдәге барлык кануннарны һәм кагыйдәләрне үтибез һәм мәгълүматны
                            яклау хезмәтләре белән хезмәттәшлек итәчәкбез. Мәгълүматларны яклау турында кануннар булмаган очракта, без
                            мәгълүматны яклауның гомуми кабул ителгән принциплары нигезендә эш итәчәкбез.
                        </li>
                    </ol>

                    <p>
                        <strong>
                            &laquo;Хозур&raquo; Нәшрият йортында<span>&nbsp; </span>конфиденциальлек сәясәте
                        </strong>
                    </p>
                    <p>
                        &laquo;Хозур&raquo; Нәшрият Йорты<span>&nbsp; </span>компаниясе Сезнең шәхси мәгълүматны һәм шәхси мәгълүмат турында
                        конфиденциальлекне сакларга омтыла. Конфиденциальлек сәясәте безнең нинди төр шәхси мәгълүмат туплавыбыз, аны ни
                        рәвешле куллануыбыз һәм саклавыбызга бәйле.
                    </p>
                    <p>
                        <strong>
                            &laquo;Хозур&raquo; Нәшрият йортында<span>&nbsp; </span>конфиденциальлек сәясәтенең кулланылу өлкәсе
                        </strong>
                    </p>
                    <p>
                        Конфиденциальлек сәясәте &laquo;Хозур&raquo; нәшрият йортының без тәкъдим иткән хезмәтләргә бәйле рәвештә җыйган
                        шәхси мәгълүматка тарала. Medrese.tatar сайты аша онлайн режимында җыелган мәгълүмат турында сүз бара.
                    </p>
                    <p>
                        Конфиденциальлек сәясәте &laquo;Хозур&raquo; Нәшрият Йорты компаниясенең веб-сайтыннан файдалану кагыйдәләренең һәм
                        шартының аерылгысыз өлеше булып тора. Шәхси мәгълүматны саклау өчен җавап бирә торган &laquo;Хозур&raquo; Нәшрият
                        Йорты компаниясе турында тулырак мәгълүматны Сез &laquo;Хокукый мәгълүмат&raquo; бүлегеннән таба аласыз:{' '}
                        <a href="http://huzur.ru/legal">
                            <span>http://huzur.ru/legal/</span>
                        </a>
                    </p>
                    <p>Конфиденциальлек сәясәте түбәндәге өлкәләргә кагыла:</p>
                    <ul>
                        <li>без контрольләми торган чит веб-сайт, платформа һәм / яки кушымталар аша җыелган мәгълүмат;</li>
                        <li>
                            &laquo;Хозур&raquo; Нәшрият Йорты компаниясенең сайтында тәкъдим ителгән сылтамалар аша узып өченче затларга
                            караган сайтта калдырылган мәгълүмат;
                        </li>
                        <li>
                            өченче затларга караган сайтларда урнаштырылган һәм безнең тарафтан финансланган баннер һәм башка реклама
                            акцияләре.
                        </li>
                    </ul>
                    <p>
                        Чит сайтларның үз конфиденциальлек сәясәте, мәгълүматны куллану кагыйдәләре һәм шартлары була ала. Өченче затларга
                        караган сайтларда эшли башлаганчы, без Сезгә аларның конфиденциальлек сәясәте белән танышырга тәкъдим итәбез.
                    </p>
                    <p>
                        <strong>Сезнең ризалыгыгыз</strong>
                    </p>
                    <p>
                        Без Сезнең ризалыктан башка Сезнең шәхси мәгълүматыгызны җыймыйбыз. Кулланмыйбыз һәм ачмыйбыз. Онлайн мәдрәсә
                        порталын кулланып, Сез &laquo;Хозур&raquo; нәшрияты йорты компаниясенә Конфиденциальлек сәясәте нигезендә үзегез
                        турында шәхси мәгълүмат җыярга, кулланырга һәм ачарга рөхсәт бирәсез.
                    </p>
                    <p>
                        Без сезнең шәхси мәгълүматыгызны әлеге конфиденциальлек сәясәте таралмый торган максатлар өчен файдалануыгызны
                        өстәмә рәвештә сорый алабыз. Сез ризалашырга тиеш түгел, әмма сез ризалык бирмәскә уйласагыз, сезнең билгеле бер
                        чараларда катнашуыгыз чикләнергә мөмкин. Әгәр дә сез өстәмә шартларга үз ризалыгыгызны бирәсез икән, алар әлеге
                        конфиденциальлек сәясәтеннән аерылган очракта өстенлек итәргә тиеш.
                    </p>
                    <p>
                        Әгәр дә сез шәхси мәгълүматны җыюга, куллануга һәм ачуга риза булмасагыз, зинһар өчен, &laquo;Хозур&raquo;
                        компаниясенең сайтларын кулланмагыз яки &laquo;Хозур&raquo; компаниясенең шәхси мәгълүматны башка ысул белән
                        бирегез.
                    </p>
                    <p>
                        <strong>Алынган мәгълүматны ничек кулланабыз?</strong>
                    </p>
                    <p>Сезнең турындагы мәгълүматны түбәндәге очракларда куллана алабыз:</p>
                    <ul>
                        <li>Безнең продуктларның сыйфатын һәм &laquo;Хозур&raquo; компаниясе сайтларының эшен яхшырту өчен;</li>
                        <li>&laquo;Хозур&raquo; нәшрият йорты сайтларын, продуктларын һәм хезмәтләрен куллануны бәяләү өчен;</li>
                        <li>безнең рекламаның нәтиҗәлелеген бәяләү өчен;</li>
                        <li>
                            Сезнең сайт белән эшегезне индивидуаль рәвештә көйләү һәм сайтка килүченең үз-үзен тотышының аноним
                            статистикасын төзү, шул исәптән, сайтка керү вакытын, сайтка алдагы керү вакытын, сезне &laquo;Хозур&raquo;
                            Нәшрият Йорты компаниясе сайтына җибәрүче сайт адресын билгеләү өчен;
                        </li>
                        <li>&laquo;Хозур&raquo; Нәшрият Йорты сайтларын файдалануны гадиләштерү өчен;</li>
                        <li>
                            киләчәктә сайт белән эшне тизләтү өчен. Мәсәлән, сайт Сезнең моңарчы мәгүлүматны истә калдыра һәм икенче тапкыр
                            сорамый;
                        </li>
                        <li>
                            &laquo;Хәзур&raquo; нәшрият йорты компаниясенең сайтларын, шул исәптән IP-адресын, интернет-браузер яки операция
                            системасы тибын карап чыгу өчен, Сез файдалана торган җайланма турында мәгълүмат җыю һәм аны челтәрдә иң югары
                            эш дәрәҗәсен тәэмин итү өчен сезнең шәхси мәгълүмат белән бәйләү өчен.
                        </li>
                    </ul>
                    <p>
                        <strong>Шәхси мәгълүматның таралуы</strong>
                    </p>
                    <p>
                        Сезнең шәхси мәгълүматны &laquo;Хозур&raquo; Нәшрият Йорты чикләрендә таратмыйбыз. Ләкин Сезнең шәхси мәгълүматны
                        өченче затларга бирү очраклары каралган. Түбәндәге очраклар:
                    </p>
                    <ul>
                        <li>
                            Суд процессын алып бара торган хокук саклау, дәүләт, хакимият органнары бездән шәхси мәгълүмат таләп итәләр.
                        </li>
                        <li>
                            Сезгә теге яки бу товар яки хезмәт тәкъдим итәргә тели торган өченче затларга бирелергә мөмкин. Ләкин бары тик
                            Сезнең рөхсәт белән.
                        </li>
                        <li>
                            &laquo;Хозур&raquo; Нәшрият Йорты компаниясенең сайты Интернет провайдер, сайт хостерлары тарафыннан, үз
                            кулланучылары турында мәгълүмат туплау өчен, кулланыла ала.
                        </li>
                    </ul>
                    <p>
                        Әгәр Сезнең шәхси мәгълүматны фаш итүне юридик яктан мөһим дип тапсак, Сезнең шәхси мәгълүматны өченче затларга,
                        оешмаларга яки аерым бер затларга бирә алабыз.
                    </p>
                    <p>
                        <strong>Конфиденциаль мәгълүматны саклауны тәэмин итү.</strong>
                    </p>
                    <p>
                        Без, Сезнең шәхси мәгълүматларыгызны саклау өчен, барлык мөмкин булган чаралар күрәбез һәм сезнең шәхси
                        мәгълүматларны анализлый торган яки эшкәртә торган өченче затлардан да шуны таләп итәбез. Сезнең шәхси
                        мәгълүматларыгызга, рөхсәт ителмәгән керү, үзгәрешләр кертү яки хокуксыз файдалануны булдырмау өчен, бары тик безнең
                        хезмәткәрләргә һәм агентларга гына рөхсәт ителә.
                    </p>
                    <p>
                        <strong>Конфиденциальлек сәясәтендәге үзгәрешләр</strong>
                    </p>
                    <p>
                        &laquo;Хозур&raquo; Нәшрият Йорты компаниясенең конфиденциальлек сәясәтенә <strong>31.12.2014 </strong>көнне соңгы
                        тапкыр үзгәрешләр&nbsp;кертелде.
                    </p>
                    <p>
                        Кирәк булган саен, без &laquo;Хозур&raquo; Нәшрият Йорты компаниясе сайтларында, аның яңартылган версиясен урнаштыру
                        юлы белән, конфиденциальлек сәясәтен үзгәртә алабыз. Шәхси мәгълүматны ничек куллануыбыз турында белеп тору өчен,
                        Сезне үзебезнең ресурсларга мөмкин кадәр ешрак керергә чакырабыз.
                    </p>
                    <p>
                        Әлеге Сәясәткә өстәмә буларак &laquo;Хозур&raquo; Нәшрият Йорты компаниясе сайтларында аерым реклама кампанияләре
                        һәм акцияләр булырга мөмкин. Алар өстәмә конфиденциальлек сәясәте яки конфиденциальлек шартлары белән җайга
                        салыначак. Без Сезгә бу өстәмә шартлар яки сәясәт белән танышырга тәкъдим итәбез, чөнки сез аларны үтәргә тиеш
                        булачаксыз. Теләсә нинди өстәмә конфиденциальлек сәясәте яки шартлары ачык кулланылышта булачак.
                    </p>

                    <Box textAlign="right">
                        <p>
                            <em>Ихтирам белән,</em>
                        </p>
                        <p>
                            <em> &laquo;Хозур&raquo; Нәшрият Йорты&nbsp;</em>
                            <a href="http://huzur.ru/">
                                <span>
                                    <em>http://huzur.ru/</em>
                                </span>
                            </a>
                        </p>
                        <p>
                            <em>Онлайн мәдрәсәнең хосусый милек иясе </em>
                            <a href="http://medrese.tatar">
                                <span>
                                    <em>http://medrese.tatar</em>
                                </span>
                            </a>
                        </p>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        ...theme.typography.body1,
        '& a': {
            color: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.primary.dark,
                textDecoration: 'none',
            },
        },
    },
}));

export default PrivacyPolicy;
