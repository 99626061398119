import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useParams } from 'react-router-dom';

import { Container, Box, Typography, Button, Theme, makeStyles } from '@material-ui/core';
import { useStores } from '../hooks/useStores';
import TeachersCarousel from '../components/TeachersCarousel';
import SubjectProgram from '../components/Subject/SubjectProgram';
import Loader from '../components/UI/Loader';
import { transformNormalContentText } from '../utils/transformNormalContentText';
import { scrollTo } from '../utils/scrollTo';

import bg from '../img/subject_page_bg.jpg';

const Subject: React.FC = observer(() => {
    const classes = useStyles();
    const { AuthStore, SubjectStore: store } = useStores();
    const { subjectId } = useParams();
    const programAnchor = useRef(null);

    /// Effects
    useEffect(() => {
        store.getSubject(subjectId);
    }, [store, subjectId]);

    //// Handlers
    const handleScrollToPrograms = () => {
        scrollTo(programAnchor?.current);
    };

    //// Renders
    const renderContent = () => {
        if (store.subject) {
            const { title, description, teachers, programs } = store.subject;

            return (
                <Box className={classes.root}>
                    <Container maxWidth="xl">
                        <Box mt={{ xxs: 7, lg: 10, xl: 14 }} mb={{ xxs: 3, xl: 5 }}>
                            <Typography variant="h5">{title}</Typography>
                        </Box>
                        <Box mb={5} maxWidth="640px">
                            <Typography
                                component="div"
                                dangerouslySetInnerHTML={{
                                    __html: transformNormalContentText(description),
                                }}
                            />
                        </Box>
                        <Box display="flex" alignItems="center" mb={{ xxs: 8, lg: 10, xl: 14 }}>
                            {!AuthStore.isAuth && (
                                <Link to="/register" className="router-link">
                                    <Box width={252} mr={3}>
                                        <Button variant="contained" color="primary" fullWidth>
                                            Теркәлү
                                        </Button>
                                    </Box>
                                </Link>
                            )}
                            <Box width={252}>
                                <Button variant="outlined" color="primary" fullWidth onClick={handleScrollToPrograms}>
                                    Фәннең программасы
                                </Button>
                            </Box>
                        </Box>
                        <Box mb={{ xxs: 8, lg: 10, xl: 14 }}>
                            <TeachersCarousel teachers={teachers} />
                        </Box>
                        <Box mb={14}>
                            <div ref={programAnchor}></div>
                            <Box mb={3}>
                                <Typography variant="h5">Фәннең программасы</Typography>
                            </Box>

                            <SubjectProgram programs={programs} showDoneText />
                        </Box>
                    </Container>
                </Box>
            );
        }

        return <div></div>;
    };

    if (store.loading) {
        return <Loader minHeight={300} />;
    }

    return renderContent();
});

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        background: `url(${bg}) no-repeat`,
        backgroundPosition: 'top right',
        backgroundSize: 'auto 856px',
        [theme.breakpoints.down('lg')]: {
            backgroundSize: 'auto 726px',
        },
        [theme.breakpoints.down('md')]: {
            backgroundPosition: '100% 6%',
            backgroundSize: 'auto 576px',
        },
        [theme.breakpoints.down('sm')]: {
            backgroundPosition: '100% 11%',
            backgroundSize: 'auto 490px',
        },
        [theme.breakpoints.down('xs')]: {
            background: 'none',
        },
    },
}));

export default Subject;
