import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Container, Box, Typography, TextField } from '@material-ui/core';

import { ISignUpEmail } from '../../types/AuthTypes';
import FormLabelCustom from '../../components/UI/FormLabelCustom';
import AgreementText from './AgreementTextScreen';
import SuccessCard from '../../components/Auth/SuccessCard';
import PasswordInput from '../../components/UI/PasswordInput';
import { useStores } from '../../hooks/useStores';
import LoaderButton from '../../components/UI/LoaderButton';

import { useStyles } from '../../components/Auth/style';
import logo from '../../img/logo.png';

const SignUp = observer(() => {
    const classes = useStyles();
    const { AuthStore: store } = useStores();
    const [values, setValues] = useState<ISignUpEmail>({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
    });

    //// Effects
    // reset to form
    useEffect(() => {
        return () => {
            store.clearConfirmEmailMessage();
            store.clearErrors();
        };
    }, [store]);

    //// Handlers
    const handleChange = (event: any) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleRegister = () => {
        store.register(values);
    };

    //// Renders
    const renderSingUpForm = () => {
        return (
            <Container maxWidth="xl">
                <Box className={classes.authRow}>
                    <Box className={classes.card}>
                        <img src={logo} alt="" className={classes.logo} />

                        <Box mb={3} textAlign="center">
                            <Typography variant="h6">Электрон почта аша теркәлергә</Typography>
                        </Box>

                        <Box width="100%" display="flex" flexDirection="column">
                            <Box mb={{ xxs: 2, sm: 3 }}>
                                <FormLabelCustom>Email</FormLabelCustom>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    error={!!store.errorMessages?.email}
                                    helperText={store.errorMessages?.email}
                                    disabled={store.loading}
                                />
                            </Box>
                            <Box mb={{ xxs: 2, sm: 3 }}>
                                <FormLabelCustom>Исем</FormLabelCustom>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    error={!!store.errorMessages?.name}
                                    helperText={store.errorMessages?.name}
                                    disabled={store.loading}
                                />
                            </Box>
                            <Box mb={2}>
                                <FormLabelCustom>Серсүз</FormLabelCustom>
                                <PasswordInput
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    error={!!store.errorMessages?.password}
                                    helperText={store.errorMessages?.password?.join('\n')}
                                    disabled={store.loading}
                                />
                            </Box>
                            <Box mb={{ xxs: 2, sm: 3 }}>
                                <FormLabelCustom>Серсүзне кабатлагыз</FormLabelCustom>
                                <PasswordInput
                                    name="confirmPassword"
                                    value={values.confirmPassword}
                                    onChange={handleChange}
                                    error={!!store.errorMessages?.confirmPassword}
                                    helperText={store.errorMessages?.confirmPassword}
                                    disabled={store.loading}
                                />
                            </Box>
                        </Box>

                        <Box width="100%" mb={2}>
                            <LoaderButton fullWidth color="primary" variant="contained" onClick={handleRegister} loading={store.loading}>
                                Теркәлү
                            </LoaderButton>
                        </Box>

                        <AgreementText />
                    </Box>
                </Box>
            </Container>
        );
    };

    return store.confirmEmailMessage ? (
        <SuccessCard email={values.email}>Без Сезгә әле генә раслау сылтамасы җибәрдек. Шул сылтама аша узыгыз.</SuccessCard>
    ) : (
        renderSingUpForm()
    );
});

export default SignUp;
