import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import icon1 from '../../img/about/icon_1.svg';
import icon2 from '../../img/about/icon_2.svg';
import icon3 from '../../img/about/icon_3.svg';

const AboutAdvantagesSection: React.FC = () => {
    return (
        <Grid container spacing={4}>
            <AdvantagItem icon={icon1} title="Сез үзегезнең җәдвәлегезне үзегез билгелисез">
                Дәресләрне үзегезгә җайлы урында һәм җайлы вакытта укый аласыз.
            </AdvantagItem>
            <AdvantagItem icon={icon2} title="Нәтиҗә бик тиз күренә">
                Тест системасы һәм заманча алымнар курсны ни дәрәҗәдә уңышлы узуны билгеләргә ярдәм итәр.
            </AdvantagItem>
            <AdvantagItem icon={icon3} title="Үз платформабыз һәм мобиль кушымта">
                Компьютер яки смартфон аша читтән торып шөгыльләнә аласыз.
            </AdvantagItem>
        </Grid>
    );
};

interface AdvantagItemProps {
    icon: string;
    title: string;
    children: string;
}

const AdvantagItem: React.FC<AdvantagItemProps> = ({ icon, title, children }) => {
    return (
        <Grid item xs={12} md={6} lg={4}>
            <Box mb={{ xxs: 3, md: 4, lg: 0 }}>
                <Box mb={{ xxs: 2, md: 3 }} maxWidth={{ xxs: 80, md: 90, lg: 116 }}>
                    <img src={icon} alt="" style={{ width: '100%' }} />
                </Box>
                <Box mb={{ xxs: 1, md: 2 }}>
                    <Typography variant="h6">{title}</Typography>
                </Box>
                <Box maxWidth={{ xxs: 320, sm: 400 }}>
                    <Typography color="textSecondary">{children}</Typography>
                </Box>
            </Box>
        </Grid>
    );
};

export default AboutAdvantagesSection;
