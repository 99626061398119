import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useStyles } from '../../components/Auth/style';

const AgreementText: React.FC = () => {
    const classes = useStyles();

    return (
        <Box maxWidth={240} textAlign="center">
            <Typography variant="body2" className={classes.agreement}>
                Әлеге аккаунтны ясап, Сез үзегез <Link to="/policy">турындагы шәхси мәгълүматны эшкәртергә Ризалык</Link> бирәсез
            </Typography>
        </Box>
    );
};

export default AgreementText;
