import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import YouTube from 'react-youtube';
import { useParams, Link, useLocation } from 'react-router-dom';
import { toJS } from 'mobx';

import {
    Container,
    Box,
    Typography,
    Theme,
    makeStyles,
    Grid,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Paper,
    Button,
    useTheme,
    useMediaQuery,
    Drawer,
} from '@material-ui/core';
import SubjectProgram from '../components/Subject/SubjectProgram';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import NotesIcon from '@material-ui/icons/Notes';
import { Icon } from '@iconify/react';
import bxDisLike from '@iconify/icons-bx/bx-dislike';
import bxLike from '@iconify/icons-bx/bx-like';

import { useStores } from '../hooks/useStores';
import AdditionalMaterialItem from '../components/Lesson/AdditionalMaterialItem';
import AudioPlayer from '../components/Lesson/AudioPlayer';
import { transformNormalContentText } from '../utils/transformNormalContentText';
import { IAddMatItemProps } from '../types/LessonTypes';
import YouTubeGetID from '../utils/YouTubeGetId';
import { ISubjectLesson, ISubjectProgram } from '../types/SubjectTypes';
import Loader from '../components/UI/Loader';

const Lesson: React.FC = observer(() => {
    const classes = useStyles();
    const { LessonStore: store, SubjectStore, ProfileStore } = useStores();
    const location = useLocation();
    const { lessonId } = useParams();
    const [lessonsMenuOpen, setLessonsMenuOpen] = useState<boolean>(false);
    const [status, setStatus] = useState('');

    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down('md'));

    // Effects
    useEffect(() => {
        return () => {
            if (SubjectStore.programs.length !== 0) {
                SubjectStore.resetPrograms();
            }
            store.resetStore();
        };
    }, [SubjectStore, store, lessonId]);

    useEffect(() => {
        window.scrollTo(0, 0);

        store.getLesson(lessonId);
    }, [lessonId, store]);

    useEffect(() => {
        const subjectId = store.lesson?.subjectId;

        if (!SubjectStore.programs.length && subjectId) {
            SubjectStore.getPrograms(subjectId);
        }
    }, [SubjectStore, store.lesson]);

    // Availability control
    useEffect(() => {
        if (store.lesson && SubjectStore.programs.length) {
            const [program] = SubjectStore.programs?.filter((program: ISubjectProgram) => program.id === store.lesson?.programId);
            const lessons = program?.lessons?.filter((lesson: ISubjectLesson) => lesson.id === parseInt(lessonId));

            if (lessons?.length && lessons[0].status !== 'notAvailable') {
                setStatus('available');
            } else {
                setStatus('notAvailable');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SubjectStore.programs, store.lesson]);

    useEffect(() => {
        if (!store.isLessonStarted && !store.lesson?.started && ProfileStore.profile?.id) {
            store.startLesson(ProfileStore.profile.id, lessonId);
        }
    }, [ProfileStore.profile, lessonId, store]);

    // Handlers
    const handleDrawerOpen = () => {
        setLessonsMenuOpen(true);
    };
    const handleDrawerClose = () => {
        setLessonsMenuOpen(false);
    };

    const handleDislike = () => {
        if (ProfileStore.profile?.id && store.lesson?.viewed) {
            store.setRating(ProfileStore.profile.id, lessonId, -1);
        }
    };
    const handleLike = () => {
        if (ProfileStore.profile?.id && store.lesson?.viewed) {
            store.setRating(ProfileStore.profile.id, lessonId, 1);
        }
    };

    // Set state lesson as start & view
    const setLessonAsStarted = () => {
        if (store.lesson?.programId) {
            SubjectStore.setLessonStarted(store.lesson.programId, parseInt(lessonId));
        }
    };
    const setLessonAsViewed = () => {
        if (!store.lesson?.testId) {
            store.completeLesson();

            if (store.lesson?.programId) {
                SubjectStore.setLessonCompleted(store.lesson.programId, parseInt(lessonId));
            }
        }
    };

    // Send to server start & view requests
    const handleLessonStart = () => {
        if (!store.isLessonStarted && !store.lesson?.started && ProfileStore.profile?.id) {
            store.startLesson(ProfileStore.profile.id, lessonId, setLessonAsStarted);
        }
    };
    const handleLessonView = () => {
        if (!store.isLessonViewed && !store.lesson?.completed && ProfileStore.profile?.id) {
            store.viewLesson(ProfileStore.profile.id, lessonId, setLessonAsViewed);
        }
    };

    // YT handler
    const handleChangeState = (event: any) => {
        const progressPersent = (event.target.playerInfo.currentTime / event.target.playerInfo.duration) * 100;

        if (progressPersent >= 75) {
            handleLessonView();
        }
    };

    // Renders
    const renderRatingBtns = () => {
        if (store.lesson) {
            return (
                <Box display="flex" alignItems="center" title={!store.lesson?.viewed ? 'Досмотрите сначала урок' : ''}>
                    <Box m={-0.5}>
                        <IconButton
                            className={store.lesson.youScore === -1 ? classes.ratingSuccess : classes.ratingDefault}
                            onClick={handleDislike}
                            disabled={!store.lesson?.viewed}
                        >
                            <Icon icon={bxDisLike} width="24" />
                        </IconButton>
                    </Box>
                    <Box m={-0.5}>
                        <IconButton
                            className={store.lesson.youScore === 1 ? classes.ratingSuccess : classes.ratingDefault}
                            onClick={handleLike}
                            disabled={!store.lesson?.viewed}
                        >
                            <Icon icon={bxLike} width="24" />
                        </IconButton>
                    </Box>
                </Box>
            );
        }
    };

    const renderLessonContent = () => {
        if (store.lesson) {
            const { description, video, audio, audioTranscription, additionalInfo } = store.lesson;

            return (
                <>
                    <Box mb={{ xxs: 2, lg: 3 }}>
                        <Box className={classes.playerWrap}>
                            <YouTube
                                videoId={YouTubeGetID(video)}
                                opts={{
                                    width: '100%',
                                    height: '100%',
                                    playerVars: {
                                        autoplay: 0,
                                        modestbranding: 1,
                                        rel: 0,
                                        showinfo: 0,
                                    },
                                }}
                                onPlay={handleLessonStart}
                                onStateChange={handleChangeState}
                                className={classes.player}
                            />
                        </Box>
                    </Box>

                    <Box mb={{ xxs: 2, lg: 3 }}>
                        <Typography
                            component="div"
                            dangerouslySetInnerHTML={{
                                __html: transformNormalContentText(description),
                            }}
                            color="textSecondary"
                        />
                    </Box>

                    {audio ? (
                        <>
                            <Box mb={{ xxs: 2, lg: 3 }}>
                                <AudioPlayer
                                    src={audio}
                                    completed={store.lesson.completed}
                                    onComplete={handleLessonView}
                                    onPlay={handleLessonStart}
                                />
                            </Box>

                            <Box mb={{ xxs: 2, lg: 3 }}>
                                <Accordion className={classes.accordion} defaultExpanded={false}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Транскрибация</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box display="flex" flexDirection="column" mt="-12px" pl={{ xxs: 4, md: 7 }}>
                                            <Typography
                                                component="div"
                                                dangerouslySetInnerHTML={{
                                                    __html: transformNormalContentText(audioTranscription),
                                                }}
                                                color="textSecondary"
                                            />
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </>
                    ) : null}

                    {additionalInfo.length > 0 && (
                        <Box mb={{ xxs: 2, lg: 3 }}>
                            <Paper elevation={0} variant="outlined" className={classes.addMaterialsWrap}>
                                <Box p={2} pb={0}>
                                    <Box mb={2}>
                                        <Typography>Өстәмә материаллар</Typography>
                                    </Box>

                                    {additionalInfo.map((addMatItem: IAddMatItemProps) => (
                                        <AdditionalMaterialItem
                                            key={addMatItem.id}
                                            type={addMatItem.type}
                                            link={addMatItem[addMatItem.type]}
                                        >
                                            {addMatItem.caption}
                                        </AdditionalMaterialItem>
                                    ))}
                                </Box>
                            </Paper>
                        </Box>
                    )}

                    {!store.lesson?.completed && store.lesson?.testId && (
                        <Box display="flex" justifyContent="flex-end">
                            <Box width="352px">
                                <Link to={`${location.pathname}/tests/${store.lesson?.testId}`} className="router-link">
                                    <Button fullWidth variant="contained" color="primary">
                                        Тест узу
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    )}
                </>
            );
        }

        return <div></div>;
    };

    const renderLesson = () => {
        if (store.lesson) {
            return (
                <>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2.2}>
                        <Typography variant="h6">{store.lesson.caption}</Typography>
                        {!isMD && renderRatingBtns()}
                    </Box>

                    {status === 'available' && renderLessonContent()}

                    {status === 'notAvailable' && (
                        <Box className={classes.notAvailable}>
                            <Typography>Урок не доступен!</Typography>
                            <Typography variant="body2">Пройдите сначала предыдущие уроки</Typography>
                        </Box>
                    )}
                </>
            );
        }

        return <div></div>;
    };

    const renderPrograms = () => {
        if (isMD) {
            return (
                <Box width="100%" display="flex" justifyContent="space-between">
                    <Button color="primary" variant="outlined" startIcon={<NotesIcon />} onClick={handleDrawerOpen}>
                        Дәресләр
                    </Button>
                    <Drawer anchor="left" open={lessonsMenuOpen} onClose={handleDrawerClose}>
                        <Box minWidth={320} pt={1} pb={3}>
                            <Box display="flex" justifyContent="flex-end" mb={1} pr={1}>
                                <IconButton onClick={handleDrawerClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <SubjectProgram programs={toJS(SubjectStore.programs)} dense />
                        </Box>
                    </Drawer>
                    {renderRatingBtns()}
                </Box>
            );
        }

        return (
            <>
                <Box mb={3}>
                    <Typography variant="h6">Дәресләр</Typography>
                </Box>
                <Box mb={4}>
                    <SubjectProgram programs={toJS(SubjectStore.programs)} dense />
                </Box>
            </>
        );
    };

    return (
        <Container maxWidth="xl">
            <Box my={{ xxs: 4, lg: 6 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                        {SubjectStore.loading && <Loader minHeight={200} />}
                        {!SubjectStore.loading && SubjectStore.programs.length ? renderPrograms() : null}
                    </Grid>

                    <Grid item xs={12} lg={8}>
                        {store.loading && <Loader minHeight={200} />}
                        {!store.loading && renderLesson()}
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});

const useStyles = makeStyles((theme: Theme) => ({
    accordion: {
        width: '100%',
        borderRadius: '8px !important',
        boxShadow: 'none',
        background: '#FFF0EB',
    },
    ratingDefault: {
        color: theme.palette.surface.main,
    },
    ratingSuccess: {
        color: theme.palette.primary.main,
    },
    playerWrap: {
        position: 'relative',
        width: '100%',
        height: '0',
        paddingBottom: '56.25%', // 56.25% = 16:9 video
        backgroundColor: '#FFF0EB',
        borderRadius: '8px',
        boxShadow: 'rgba(0, 0, 0, 0.48)',
        overflow: 'hidden',
    },
    player: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
    addMaterialsWrap: {
        borderRadius: 8,
    },
    notAvailable: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        minHeight: 200,
        color: theme.palette.text.secondary,
    },
}));

export default Lesson;
