import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { Container, Box, Typography } from '@material-ui/core';

import FormLabelCustom from '../../components/UI/FormLabelCustom';
import LoaderButton from '../../components/UI/LoaderButton';
import { useStores } from '../../hooks/useStores';
import PasswordInput from '../../components/UI/PasswordInput';

import logo from '../../img/logo.png';
import { useStyles } from '../../components/Auth/style';

const ResetPasswordForm: React.FC = observer(() => {
    const { AuthStore: store } = useStores();
    const classes = useStyles();
    const history = useHistory();
    const { email, token } = queryString.parse(history.location.search);

    const [values, setValues] = useState({
        email: '',
        token: '',
        newPassword: '',
        confirmNewPassword: '',
    });

    //// Effects
    useEffect(() => {
        return () => {
            store.clearConfirmEmailMessage();
            store.clearErrors();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store]);

    useEffect(() => {
        if (email && token) {
            setValues({
                ...values,
                email: email as string,
                token: token as string,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, token]);

    //// Handlers
    const handleChange = (event: any) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleSave = () => {
        store.saveNewPassword(values, history);
    };

    //// Renders
    return (
        <Container maxWidth="xl">
            <Box className={classes.authRow}>
                <Box className={classes.card}>
                    <img src={logo} alt="" className={classes.logo} />

                    <Box mb={4}>
                        <Typography variant="h6">Яңа серсүз кую</Typography>
                    </Box>

                    <Box width="100%">
                        <Box mb={{ xxs: 2, md: 3 }}>
                            <FormLabelCustom required>Яңа серсүз</FormLabelCustom>
                            <PasswordInput
                                name="newPassword"
                                value={values.newPassword}
                                onChange={handleChange}
                                error={!!store.errorMessages?.password}
                                helperText={store.errorMessages?.password}
                                disabled={store.loading}
                            />
                        </Box>
                        <Box mb={{ xxs: 3, lg: 5 }}>
                            <FormLabelCustom required>Серсүзне кабатлагыз</FormLabelCustom>
                            <PasswordInput
                                name="confirmNewPassword"
                                value={values.confirmNewPassword}
                                onChange={handleChange}
                                error={!!store.errorMessages?.password_confirmation}
                                helperText={store.errorMessages?.password_confirmation}
                                disabled={store.loading}
                            />
                        </Box>
                        <LoaderButton fullWidth color="primary" variant="contained" onClick={handleSave} loading={store.loading}>
                            Сакларга
                        </LoaderButton>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
});

export default ResetPasswordForm;
