import { action, computed, observable, runInAction } from 'mobx';
import { api } from '../api';
import { stores } from '../contexts/stores';
import { ISubjectItem, ISubjectLesson, ISubjectProgram, ISubject } from '../types/SubjectTypes';
import { transformSubjectData, transformProgramData } from '../utils/dataTransformers';
import { getProgramsWithStatus } from '../utils/statusControl/getProgramsWithStatus';

export class SubjectStore {
    @observable loading = false;

    @observable subjects: ISubjectItem[] = [];

    // api not transformed date
    @observable subjectData: ISubject | null = null;
    @observable programsData: ISubjectProgram[] = [];

    // Computed actions
    @computed
    get subject(): ISubject | null {
        const { subjectData } = this;

        if (subjectData) {
            if (stores.AuthStore.isAuth) {
                return {
                    ...subjectData,
                    programs: getProgramsWithStatus(subjectData.programs),
                };
            } else {
                return subjectData;
            }
        }

        return null;
    }

    @computed
    get programs(): ISubjectProgram[] {
        if (stores.AuthStore.isAuth) {
            return getProgramsWithStatus(this.programsData);
        } else {
            return this.programsData;
        }
    }

    // Actions
    @action
    getSubjects = () => {
        this.loading = true;

        api.get('/courses')
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        this.subjects = response.data.data.data?.map((subject: any) => transformSubjectData(subject));
                    });
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    };

    @action
    getSubject = (subjectId: number) => {
        this.loading = true;

        api.get(`/courses/${subjectId}`)
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        this.subjectData = transformSubjectData(response.data.data);
                    });
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    };

    @action
    getPrograms = (subjectId: number) => {
        this.loading = true;

        api.get(`/lessons/for_course/${subjectId}`)
            .then(response => {
                if (response.data.success) {
                    runInAction(() => {
                        this.programsData = response.data.data.map((program: any) => transformProgramData(program));
                    });
                }
            })
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    };

    @action
    setLessonStarted = (programId: number, lessonId: number) => {
        const programIndex = this.programs?.findIndex((program: ISubjectProgram) => program.id === programId);
        const lessonIndex = this.programs[programIndex].lessons.findIndex((lesson: ISubjectLesson) => lesson.id === lessonId);

        this.programsData[programIndex].lessons[lessonIndex] = {
            ...this.programs[programIndex].lessons[lessonIndex],
            started: true,
        };
    };

    @action
    setLessonCompleted = (programId: number, lessonId: number) => {
        const programIndex = this.programs?.findIndex((program: ISubjectProgram) => program.id === programId);
        const lessonIndex = this.programs[programIndex].lessons.findIndex((lesson: ISubjectLesson) => lesson.id === lessonId);
        const isLastLesson = this.programs[programIndex].lessons.length === lessonIndex + 1;

        this.programsData[programIndex].lessons[lessonIndex] = {
            ...this.programsData[programIndex].lessons[lessonIndex],
            completed: true,
        };

        if (isLastLesson) {
            setTimeout(() => {
                runInAction(() => {
                    this.programsData[programIndex] = {
                        ...this.programsData[programIndex],
                        completed: true,
                    };
                });
            }, 400);
        }
    };

    @action
    resetPrograms = () => {
        this.programsData = [];
    };
}
