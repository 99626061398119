import React from 'react';
import { Grid, Typography, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { ITestQuestionResult, ITestQuestionResultAnswer } from '../../types/LessonTestTypes';
import { useStyles } from './style';

interface ITestQuestionResultProps {
    question: ITestQuestionResult;
    index: number;
}

const TestQuestionResult: React.FC<ITestQuestionResultProps> = ({ question, index }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));
    const { query_caption, answers } = question;

    return (
        <Grid container spacing={isXS ? 2 : 3}>
            <Grid item>
                <div className={classes.number}>
                    <Typography variant={isXS ? 'subtitle1' : 'h6'}>{index}</Typography>
                </div>
            </Grid>
            <Grid item xs>
                <Box mb={2}>
                    <Typography variant="h6">{query_caption}</Typography>
                </Box>
                {answers.length ? (
                    answers.map((answer: ITestQuestionResultAnswer) => (
                        <Box mb={2} key={answer.id}>
                            <Typography>{answer.caption}</Typography>
                        </Box>
                    ))
                ) : (
                    <Typography>Җавап сайланмаган</Typography>
                )}
            </Grid>
        </Grid>
    );
};

export default TestQuestionResult;
